import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import ProductsWrapper from "./ProductsWrapper";


class App extends Component {
  render() {
    return (
      <div>
        <div className="container mt-3">
          <ToastContainer />
          <Routes>
            <Route path="/products/:owner" element={<ProductsWrapper />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
